import { useState } from 'react';
import { Link } from 'react-router-dom';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import i18n from '../common/i18n';
import {
  AUTOGESTION, COMMERCE, ORIGINACION_WT, getEnv,
} from '../../api/env';

const ORIGINACION_WT_LINK = getEnv(ORIGINACION_WT);

const SideBarMenu = () => {
  const navItems = [
    i18n.headerMenuHome,
    i18n.headerMenuPhysicalCommerce,
    i18n.headerMenuBlog,
    i18n.headerMenuHelp,
  ];
  const [mobileOpen, setMobileOpen] = useState(false);
  const COMMERCE_LINK = getEnv(COMMERCE);
  const CLIENT_LINK = getEnv(AUTOGESTION);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const pushEvent = () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'select_item',
      ecommerce: {
        items: [{
          item_name: 'Tradicional Web',
        }],
      },
    });
  };

  return (
    <>
      <Drawer
        variant="temporary"
        open={mobileOpen}
        anchor="right"
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
          <List className="menuHamburguesa">
            {navItems.map((item) => (item
                  && (
                    <ListItem key={item?.title} disablePadding>
                      <Link to={item?.route} style={{ textDecoration: 'none' }}>
                        <ListItemButton sx={{ textAlign: 'center' }}>
                          <ListItemText primary={item?.title} className="menuHamburguesa" />
                        </ListItemButton>
                      </Link>
                    </ListItem>
                  )
            ))}
            <a
              href={COMMERCE_LINK}
              target="_blank"
              rel="noopener noreferrer nofollow"
              style={{ textDecoration: 'none' }}
            >
              <ListItem key={i18n.headerCommerce} disablePadding>
                <ListItemButton sx={{ textAlign: 'left' }}>
                  <ListItemText primary={i18n.headerCommerce} className="menuHamburguesa" />
                </ListItemButton>
              </ListItem>
            </a>
            <a
              href={CLIENT_LINK}
              target="_blank"
              rel="noopener noreferrer nofollow"
              style={{ textDecoration: 'none' }}
            >
              <ListItem key={i18n.headerClient} disablePadding>
                <ListItemButton sx={{ textAlign: 'left' }}>
                  <ListItemText primary={i18n.headerClient} className="menuHamburguesa" />
                </ListItemButton>
              </ListItem>
            </a>
            <a
              href={ORIGINACION_WT_LINK}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              <Button onClick={pushEvent} variant="contained" className="ButtonMagenta">
                {i18n.wtButtonCredit}
              </Button>
            </a>
          </List>
        </Box>
      </Drawer>
      <IconButton
        onClick={handleDrawerToggle}
        className="iconButton"
      >
        <MenuIcon />
      </IconButton>
    </>
  );
};

export default SideBarMenu;
