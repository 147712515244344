import { useEffect } from 'react';
import {
  Box, Button, Grid, Typography, useMediaQuery, Link,
} from '@mui/material';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { createTheme, ThemeProvider, responsiveFontSizes } from '@mui/material/styles';
import i18n from '../common/i18n';
import Banner from '../common/Banner';
import '../../assests/css/usStyle.css';
import bannerImage from '../../assests/images/home/bannerHome.svg';
import rocketIcon from '../../assests/images/us/cohete.png';
import billsIcon from '../../assests/images/us/money.png';
import trolleyIcon from '../../assests/images/us/carrito.svg';
import logoPoincenot from '../../assests/images/us/logoPoincenot.png';
import teamWork from '../../assests/images/us/teamWork.png';
import bindGroup from '../../assests/images/us/grupoBind.svg';
import logoCredicuotas from '../../images/logoCredicuotas.png';
import logoMercadoLibre from '../../assests/images/us/mercadoLibre.svg';
import logoCnv from '../../assests/images/us/cnv.svg';
import logoHonda from '../../assests/images/us/honda.svg';
import logoUber from '../../assests/images/us/uber.svg';
import logoShield from '../../assests/images/investors/shield.svg';
import imgTeamIdeas from '../../assests/images/us/equipoIdeas.png';
import {
  COMMERCE, ORIGINACION_WT, JOBS_URL, getEnv,
} from '../../api/env';
import HeaderTags from '../common/HeaderTags';

const Us = () => {
  let theme = createTheme({
    palette: {
      magenta: {
        main: '#DF1365',
        contrastText: '#fff',
      },
    },
  });
  theme = responsiveFontSizes(theme);
  const matchesMobile = useMediaQuery('(max-width: 1201px)');
  const COMMERCE_LINK = getEnv(COMMERCE);
  const ORIGINACION_WT_LINK = getEnv(ORIGINACION_WT);
  const JOBS_LINK = getEnv(JOBS_URL);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);
  return (
    <>
      <HeaderTags title={i18n.footerWe} content={i18n.metaDescriptionUs} />
      <Box mt={10}>
        <Banner
          title={i18n.usTitle}
          subtitle={i18n.usSubtitle}
          backgroundImage={bannerImage}
          iconImage={imgTeamIdeas}
        />
      </Box>
      <Box className="flexCenter widthBoxTypography">
        <Typography variant="h3" fontWeight={700} className="lettersGrey widthBoxConsumer marginTitleUs">
          {i18n.usCompany}
        </Typography>
      </Box>
      <Box className="flexCenter widthBoxTypography">
        <Typography variant="h4" fontWeight={600} className="lettersGrey widthBoxConsumer" mt={2}>
          {i18n.usWhatWeDo}
        </Typography>
      </Box>

      <Grid container className="spaceBetween">
        <Grid item xs={1} />
        <Grid item xs={10}>
          <Grid container>
            <Grid item xs={12} lg={4} className="flexCenter">
              <Box className="boxCircleUs">
                <img src={rocketIcon} alt="Cohete" className="imgRocket" />
              </Box>
              <Box mt={2} className="widthBoxTypography">
                <Typography variant="h6" fontWeight={700} className="lettersMagentaVersionThree">
                  {i18n.usSimple}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} lg={4} className="flexCenter">
              <Box className="boxCircleUs marginCircle flexCenter">
                <img src={billsIcon} alt="Billetes" className="imgBills" />
              </Box>
              <Box mt={2} className="widthBoxTypography">
                <Typography variant="h6" fontWeight={700} className="lettersMagentaVersionThree">
                  {i18n.usAccreditation}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} lg={4} className="flexCenter">
              <Box className="boxCircleUs marginCircle flexCenter">
                <img src={trolleyIcon} alt="Carrito" className="imgTrolley" />
              </Box>
              <Box mt={2} className="widthBoxTypography">
                <Typography variant="h6" fontWeight={700} className="lettersMagentaVersionThree">
                  {i18n.usShop}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1} />
      </Grid>
      { matchesMobile && <Box className="boxVector" /> }
      <Box className="boxValueProposal">
        <Grid container>
          <Grid item xs={1} />
          <Grid item xs={10}>
            <Grid container>
              <Grid item xs={12} lg={6}>
                <Box className="flexCenter lettersWhiteOnly boxValue">
                  <Box>
                    <Box className="widthTitleSmall">
                      <Typography variant="h3" fontWeight={700} className="lettersWhiteOnly">
                        {i18n.usValueTitle}
                      </Typography>
                    </Box>
                    <Typography variant="h6" component="h3" fontWeight={400} className="spaceText">
                      {i18n.usValueTextFirstOne}
                      <Box fontWeight={900} display="inline">
                        {i18n.usValueTextFirstTwo}
                      </Box>
                      {i18n.usValueTextFirstThree}
                    </Typography>
                    <Typography variant="h6" component="h3" fontWeight={400} className="spaceText">
                      {i18n.usValueTextSecondOne}
                      <Box fontWeight={900} display="inline">
                        {i18n.usValueTextSecondTwo}
                      </Box>
                      {i18n.usValueTextSecondThree}
                      <Box fontWeight={900} display="inline">
                        {i18n.usValueTextSecondFour}
                      </Box>
                      {i18n.usValueTextSecondFive}
                      <Box fontWeight={900} display="inline">
                        {i18n.usValueTextSecondSix}
                      </Box>
                      {i18n.usValueTextSecondSeven}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Box className="flexCenter boxValueIcon">
                  <img src={teamWork} alt="check" className="imgTeamWork" />
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={1} />
        </Grid>
      </Box>
      <Box className="flexCenter">
        <Typography variant="h3" fontWeight={700} className="lettersGrey widthBoxConsumer marginTitleUs">
          {i18n.usHistory}
        </Typography>
      </Box>
      <ThemeProvider theme={theme}>
        <Box className="spaceBetween lettersGrey">
          <Timeline className="spaceSubContent lettersGrey" position="alternate">
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="magenta" />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Typography variant="h6" fontWeight={700}>{i18n.usHistoryFirstYear}</Typography>
                <Typography variant="p">{i18n.usHistoryFirstText}</Typography>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="magenta" />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Typography variant="h6" fontWeight={700}>{i18n.usHistorySecondYear}</Typography>
                <Typography variant="p">{i18n.usHistorySecondTextOne}</Typography>
                <Box>
                  <Typography variant="p">{i18n.usHistorySecondTextTwo}</Typography>
                </Box>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="magenta" />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Typography variant="h6" fontWeight={700}>{i18n.usHistoryThirdYear}</Typography>
                <Typography variant="p">{i18n.usHistoryThirdText}</Typography>
                { matchesMobile ? (
                  <>
                    <Box>
                      <img src={bindGroup} alt="Grupo BIND" className="imgBindGroup" />
                    </Box>
                    <Box className="widthBoxTypography">
                      <img src={logoCredicuotas} alt="Credicuotas" className="imgCredicuotas" />
                    </Box>
                  </>
                ) : (
                  <Box className="boxHistoryIcon">
                    <img src={bindGroup} alt="Grupo BIND" className="imgBindGroup" />
                    <img src={logoCredicuotas} alt="Credicuotas" className="imgCredicuotas" />
                  </Box>
                )}
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="magenta" />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Typography variant="h6" fontWeight={700}>{i18n.usHistoryFourthYear}</Typography>
                <Typography variant="p">{i18n.usHistoryFourthTextOne}</Typography>
                <Typography variant="p">{i18n.usHistoryFourthTextTwo}</Typography>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="magenta" />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Typography variant="h6" fontWeight={700}>{i18n.usHistoryFifthYear}</Typography>
                <Typography variant="p">{i18n.usHistoryFifthTextTwo}</Typography>
                <Box className="boxImagesHistory">
                  <img src={logoPoincenot} alt="Poincenot" className="imgPoincenot" />
                </Box>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="magenta" />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Typography variant="h6" fontWeight={700}>{i18n.usHistorySixthYear}</Typography>
                <Typography variant="p">{i18n.usHistorySixthTextOne}</Typography>
                <Typography variant="p">{i18n.usHistorySixthTextTwo}</Typography>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="magenta" />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Typography variant="h6" fontWeight={700}>{i18n.usHistorySeventhYear}</Typography>
                <Typography variant="p">
                  {i18n.usHistorySeventhTextOne}
                  <a
                    href={ORIGINACION_WT_LINK}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: 'inherit' }}
                  >
                    {i18n.usHistorySeventhTextTwo}
                  </a>
                </Typography>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="magenta" />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Typography variant="h6" fontWeight={700}>{i18n.usHistoryEighthYear}</Typography>
                <Typography variant="p">
                  {i18n.usHistoryEighthTextOne}
                  <a
                    href={COMMERCE_LINK}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: 'inherit' }}
                  >
                    {i18n.usHistoryEighthTextTwo}
                  </a>
                </Typography>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="magenta" />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Typography variant="h6" fontWeight={700}>{i18n.usHistoryNinethYear}</Typography>
                <Typography variant="p">{i18n.usHistoryNinethText}</Typography>
                <Box>
                  <img src={logoHonda} alt="Honda" className="imgHonda" />
                  <img src={logoUber} alt="Uber" className="imgUberUs" />
                </Box>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="magenta" />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Typography variant="h6" fontWeight={700}>{i18n.usHistoryTenthYear}</Typography>
                <Typography variant="p">{i18n.usHistoryTenthTextOne}</Typography>
                <Box className="boxMercadoLibreIcon">
                  <img src={logoMercadoLibre} alt="MercadoLibre" className="imgMercadoLibre" />
                </Box>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="magenta" />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Typography variant="h6" fontWeight={700}>{i18n.usHistoryEleventhYear}</Typography>
                <Typography variant="p">{i18n.usHistoryEleventhTextOne}</Typography>
                <Box className="boxImagesHistory">
                  <img src={logoCnv} alt="CNV" className="imgCnv" />
                </Box>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="magenta" />
              </TimelineSeparator>
              <TimelineContent>
                <Typography variant="h6" fontWeight={700}>{i18n.usHistoryTwelveYear}</Typography>
                <Typography variant="p">{i18n.usHistoryTwelveTextOne}</Typography>
                <Box mt={2} display="flex" flexDirection="row-reverse">
                  <img src={logoShield} alt="CNV" width="50px" />
                </Box>
              </TimelineContent>
            </TimelineItem>

          </Timeline>
        </Box>
      </ThemeProvider>

      <Box className="spaceBetween">
        <Grid container pt={4} pb={4} style={{ backgroundColor: '#F9CFD4' }}>
          <Grid item xs={12} lg={12} className="flexCenter">
            <Box className="textLeft" style={{ width: '100%', textAlign: 'center' }}>
              <Typography variant="h4" fontWeight={600} className="lettersGrey">
                {i18n.usJobsTitle}
              </Typography>
              <Box pt={2}>
                <Link
                  href={JOBS_LINK}
                  target="_blank"
                  style={{ textDecoration: 'none' }}
                >
                  <Button
                    variant="contained"
                    className="ButtonMagenta"
                  >
                    {i18n.usJobsCta}
                  </Button>
                </Link>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Us;
