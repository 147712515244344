import { useState, useRef, useEffect } from 'react';
import {
  Box, Button, Grid, Accordion, AccordionDetails, AccordionSummary, Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import i18n from '../common/i18n';
import Banner from '../common/Banner';
import iconBanner from '../../assests/images/help/womanSearch.png';
import bannerImage from '../../assests/images/home/bannerHome.svg';
import iconMoney from '../../assests/images/help/billeteHelp.svg';
import iconShop from '../../assests/images/help/carritoHelp.svg';
import iconCommerce from '../../assests/images/help/comerciosHelp.png';
import whatsappLogo from '../../images/contact/whatsapp.svg';
import email from '../../images/contact/email.svg';
import phone from '../../images/contact/phone.svg';
import map from '../../assests/images/help/mapaSigno.png';
import HelpWtComponent from '../helpSections/helpWt';
import HelpWcComponent from '../helpSections/helpWc';
import HelpCommerceComponent from '../helpSections/helpCommerce';
import {
  ORIGINACION_WT, getEnv,
} from '../../api/env';
import '../../assests/css/helpStyle.css';
import HeaderTags from '../common/HeaderTags';

const Help = () => {
  const [expandedWc, setExpandedWc] = useState(false);
  const [expandedWt, setExpandedWt] = useState(false);
  const [expandedCommerce, setExpandedCommerce] = useState(false);
  const refWc = useRef(null);
  const refWt = useRef(null);
  const refCommerce = useRef(null);
  const ORIGINACION_WT_LINK = getEnv(ORIGINACION_WT);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);

  const changeAccordionWc = () => {
    setExpandedWc(!expandedWc);
    if (expandedWc === false) {
      refWc.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const changeAccordionWt = () => {
    setExpandedWt(!expandedWt);
    if (expandedWt === false) {
      refWt.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const changeAccordionCommerce = () => {
    setExpandedCommerce(!expandedCommerce);
    if (expandedCommerce === false) {
      refCommerce.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      <HeaderTags title={i18n.footerHelp} content={i18n.metaDescriptionHelp} />
      <Box mt={10}>
        <Banner
          title={i18n.footerHelp}
          subtitle={i18n.helpSubtitle}
          backgroundImage={bannerImage}
          iconImage={iconBanner}
        />
      </Box>
      <Box className="flexCenter spaceBetween">
        <Typography variant="h3" fontWeight={700} className="lettersGrey widthBoxHelpTypography">
          {i18n.helpProducts}
        </Typography>
      </Box>
      <Grid container className="spaceBetween">
        <Grid item xs={1} />
        <Grid item xs={10}>
          <Grid container className="boxFlexHelp">
            <Grid item xs={12} lg={3}>
              <Button onClick={changeAccordionWt} color="inherit">
                <Box className="boxIconsHelp flexCenter" backgroundColor="#FFC761">
                  <Box className="boxImgHelp">
                    <img src={iconMoney} alt="success" className="imgHelpIconBills" />
                  </Box>
                  <Box className="widthBoxHelpTypography">
                    <Typography
                      variant="h6"
                      component="h3"
                      className="lettersGreyParapgraph"
                      style={{ lineHeight: 1.3 }}
                    >
                      {i18n.helpWt}
                    </Typography>
                  </Box>
                </Box>
              </Button>
            </Grid>
            <Grid item xs={12} lg={3}>
              <Button onClick={changeAccordionWc} color="inherit">
                <Box className=" boxIconsHelp flexCenter marginBoxHelp" backgroundColor="#EFBDDB">
                  <Box className="boxImgHelp">
                    <img src={iconShop} alt="success" className="imgHelpIconShop" />
                  </Box>
                  <Box className="widthBoxHelpTypography">
                    <Typography
                      variant="h6"
                      component="h3"
                      className="lettersGreyParapgraph"
                      style={{ lineHeight: 1.3 }}
                    >
                      {i18n.helpWc}
                    </Typography>
                  </Box>
                </Box>
              </Button>
            </Grid>
            <Grid item xs={12} lg={3}>
              <Button onClick={changeAccordionCommerce} color="inherit">
                <Box className="boxIconsHelp flexCenter marginBoxHelp" backgroundColor="#F9CFD4">
                  <Box className="boxImgHelp">
                    <img src={iconCommerce} alt="success" className="imgHelpIconCommerce" />
                  </Box>
                  <Box className="widthBoxHelpTypography">
                    <Typography variant="h6" component="h3" className="lettersGreyParapgraph">
                      {i18n.helpCommerce}
                    </Typography>
                  </Box>
                </Box>
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1} />
      </Grid>
      {/* preguntas divididas por secciones */}
      <Box className="flexCenter spaceTitle">
        <Typography variant="h3" fontWeight={700} className="lettersGrey widthBoxHelpTypography">
          {i18n.faq}
        </Typography>
      </Box>
      <Grid container className="spaceBetween">
        <Grid item xs={1} />
        <Grid item xs={10}>
          <Accordion className="marginBottomQuestions" expanded>
            <AccordionSummary
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="h5" component="h4" fontWeight={600}>{i18n.helpAboutCredicuotas}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="h6" className="questions">{i18n.helpWhoWeAre}</Typography>
              <Typography component="p" className="answers">{i18n.helpAnswerWhoWeAre}</Typography>
              <Typography variant="h6" className="questions paddingQuestions">
                {i18n.helpWhoWeAreSecondQuestion}
              </Typography>
              <Typography component="p" className="answers">
                {i18n.helpAnswerWhoWeAreSecondAnswerOne}
                <a
                  href={ORIGINACION_WT_LINK}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: 'inherit' }}
                >
                  {i18n.helpOurWeb}
                </a>
                {i18n.helpAnswerWhoWeAreSecondAnswerTwo}
              </Typography>
              <Typography variant="h6" className="questions paddingQuestions">
                {i18n.helpWhoWeAreThirdQuestion}
              </Typography>
              <Typography component="p" className="answers">{i18n.helpAnswerWhoWeAreThirdAnswer}</Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion className="marginBottomQuestions" expanded={expandedWt} ref={refWt}>
            <AccordionSummary
              onClick={() => setExpandedWt(!expandedWt)}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="h5" component="h4" fontWeight={600}>{i18n.helpWt}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <HelpWtComponent />
            </AccordionDetails>
          </Accordion>
          <Accordion className="marginBottomQuestions" expanded={expandedWc} ref={refWc}>
            <AccordionSummary
              onClick={() => setExpandedWc(!expandedWc)}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="h5" component="h4" fontWeight={600}>{i18n.helpWc}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <HelpWcComponent />
            </AccordionDetails>
          </Accordion>
          {/* <Accordion className="marginBottomQuestions" expanded={expandedBnpl} ref={refBnpl}>
            <AccordionSummary
              onClick={() => setExpandedBnpl(!expandedBnpl)}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="h5" component="h4" fontWeight={600}>{i18n.helpBnpl}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <helpBnplComponent />
            </AccordionDetails>
          </Accordion> */}
          <Accordion className="marginBottomQuestions" expanded={expandedCommerce} ref={refCommerce}>
            <AccordionSummary
              onClick={() => setExpandedCommerce(!expandedCommerce)}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="h5" component="h4" fontWeight={600}>{i18n.helpCommerce}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <HelpCommerceComponent />
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={1} />
      </Grid>
      <Grid container>
        <Grid item xs={0.85} lg={1} />
        <Grid item xs={10.3} lg={10}>
          <Box className="boxChannelsHelp spaceBetween">
            <Grid container>
              <Grid item lg={0.5} />
              <Grid item xs={12} lg={3.6}>
                <Box className="flexCenter boxHelpChannelsTitle">
                  <Typography variant="h3" fontWeight={800} className="lettersMagentaNew">
                    {i18n.helpChannels}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} lg={2.6}>
                <a href="http://wa.me/+5491164575091" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
                  <Box className="flexCenter marginBoxContact">
                    <img src={whatsappLogo} alt="whatsapp" className="imgContact" />
                    <Box className="flexCenter widthBoxTypography" mt={2}>
                      <Typography variant="h5" fontWeight={500} className="lettersGreyParapgraph">
                        {i18n.infoWhatsapp}
                      </Typography>
                    </Box>
                    <Typography variant="body1" className="lettersVersionTwo">{i18n.infoWhatsappNumber}</Typography>
                  </Box>
                </a>
              </Grid>
              <Grid item xs={12} lg={2.6}>
                <a
                  href="mailTo: clientes@credicuotas.com.ar"
                  rel="noopener noreferrer"
                  style={{ textDecoration: 'none', color: '#383838' }}
                >
                  <Box className="flexCenter marginBoxContact">
                    <img src={email} alt="email" className="imgContact" />
                    <Box className="flexCenter widthBoxTypography" mt={2}>
                      <Typography variant="h5" fontWeight={500} className="lettersGreyParapgraph">
                        {i18n.infoMail}
                      </Typography>
                    </Box>
                    <Typography variant="body1" className="lettersVersionTwo">{i18n.infoMailClient}</Typography>
                  </Box>
                </a>
              </Grid>
              <Grid item xs={12} lg={2.6}>
                <Box className="flexCenter marginBoxContact">
                  <img src={phone} alt="phone" className="imgContact" />
                  <Box className="flexCenter widthBoxTypography" mt={2}>
                    <Typography variant="h5" fontWeight={500} className="lettersGreyParapgraph">
                      {i18n.infoTelephone}
                    </Typography>
                  </Box>
                  <Typography variant="body1" className="lettersVersionTwo">{i18n.infoTelephoneNumber}</Typography>
                </Box>
              </Grid>
              <Grid item lg={0.1} />
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={0.85} lg={1} />
      </Grid>
      <Box className="boxBranch">
        <Grid container>
          <Grid item lg={1.4} />
          <Grid item xs={12} lg={5.6}>
            <Box>
              <Box className="boxBranchInfo" mt={5}>
                <Box className="widthBoxTypography boxHelpChannelsTitle">
                  <Typography variant="h3" fontWeight={700} className="lettersGrey widthBoxHelpTypography">
                    {i18n.helpBranch}
                  </Typography>
                </Box>
                <Box className="spaceMedium boxParagraphBranch">
                  <Typography component="p" fontSize={20}>
                    {i18n.helpBranchInfoOne}
                    <Box fontWeight={500} fontSize={20} display="inline">
                      {i18n.helpBranchInfoTwo}
                    </Box>
                    <Box className="spaceParagraph">
                      {i18n.helpBranchInfoThree}
                      <Box fontWeight={500} fontSize={20} display="inline">
                        {i18n.helpBranchInfoFour}
                      </Box>
                    </Box>
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={5}>
            <Box className="flexCenter">
              <img src={map} alt="mapaSigno" className="imgMap" />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Help;
